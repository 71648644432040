import React from 'react';

function Carasoul() {
  return (
    <section className="hero-with-carousel">
      <div className="hero-with-carousel__carousel-wrapper">
        <div className="hero-with-carousel-images">
          <div className="hero-with-carousel-images__items-wrapper" aria-hidden="true">
            <div className="hero-with-carousel-images__items" style={{ transform: 'translateX(-192rem)' }}>
              <div className="hero-with-carousel-images__previous-items">
                <div className="hero-with-carousel-images__item hero-with-carousel-images__item--previous">
                  <picture>
                    <source
                      srcset="
                            https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;q=70
                          "
                      media="(min-width: 768px)"
                    />
                    <img
                      className="hero-with-carousel-images__image"
                      src="https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                      width="1000"
                      height="660"
                      alt=""
                      loading="eager"
                    />
                  </picture>
                </div>
                <div className="hero-with-carousel-images__item hero-with-carousel-images__item--previous">
                  <picture>
                    <source
                      srcset="
                            https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;q=70
                          "
                      media="(min-width: 768px)"
                    />
                    <img
                      className="hero-with-carousel-images__image"
                      src="https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                      width="1000"
                      height="660"
                      alt=""
                      loading="eager"
                    />
                  </picture>
                </div>
                <div className="hero-with-carousel-images__item hero-with-carousel-images__item--previous">
                  <picture>
                    <source
                      srcset="
                            https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;q=70
                          "
                      media="(min-width: 768px)"
                    />
                    <img
                      className="hero-with-carousel-images__image"
                      src="https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                      width="1000"
                      height="660"
                      alt=""
                      loading="eager"
                    />
                  </picture>
                </div>
                <div className="hero-with-carousel-images__item hero-with-carousel-images__item--previous">
                  <picture>
                    <source
                      srcset="
                            https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;q=70
                          "
                      media="(min-width: 768px)"
                    />
                    <img
                      className="hero-with-carousel-images__image"
                      src="https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                      width="1000"
                      height="660"
                      alt=""
                      loading="eager"
                    />
                  </picture>
                </div>
                <div className="hero-with-carousel-images__item hero-with-carousel-images__item--previous">
                  <picture>
                    <source
                      srcset="
                            https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;q=70
                          "
                      media="(min-width: 768px)"
                    />
                    <img
                      className="hero-with-carousel-images__image"
                      src="https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                      width="1000"
                      height="660"
                      alt=""
                      loading="eager"
                    />
                  </picture>
                </div>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item is-active">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item is-active">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/1J39zRmv1Cicd33FIC7zAC/49b786d19e444a2e3ce42a58b99d65a7/homepage-hero-with-carousel-images-01.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/3siGAx2LsOf1IVcyoyk0JY/18d5182fbdc4826f47e53a18ceeb0efe/homepage-hero-with-carousel-images-02.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/42mEvyvBAYhgY7bxMvA3kT/61a7d19bbe2f1029fa765654280cec3b/homepage-hero-with-carousel-images-03.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/5iTNNiVK4VSzHUDDetNEIn/108ed3b6ce5caf9a804fe5f2a98c9cd6/homepage-hero-with-carousel-images-04.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
              <div className="hero-with-carousel-images__item">
                <picture>
                  <source
                    srcset="
                          https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;q=70
                        "
                    media="(min-width: 768px)"
                  />
                  <img
                    className="hero-with-carousel-images__image"
                    src="https://images.ctfassets.net/4rh3om84a7gw/11FeJ70xBweq7XZGefqUQV/3ad9e8abda8b63732c6cde90405b301a/homepage-hero-with-carousel-images-05.jpg?fm=webp&amp;w=400&amp;h=528&amp;fit=fill&amp;q=70"
                    width="1000"
                    height="660"
                    alt=""
                    loading="eager"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-with-carousel__main">
        <div className="hero-with-carousel__image-wrapper">
          <img
            alt=""
            sizes="
        (min-resolution: 2.1dppx) and (max-width: 768px) 50vw,
        1620w"
            srcset="
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=384&amp;q=70   384w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=640&amp;q=70   640w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=750&amp;q=70   750w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=828&amp;q=70   828w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=1080&amp;q=70 1080w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=1200&amp;q=70 1200w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=1920&amp;q=70 1920w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=2048&amp;q=70 2048w,
                  https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=3840&amp;q=70 3840w
                "
            src="https://images.ctfassets.net/4rh3om84a7gw/5kQl2j5oeN005s3OucOHU6/a8abbf385b72ae7b3e0b1c89dfc86b22/homepage-hero-with-carousel-image.png?fm=webp&amp;w=3840&amp;q=70"
            width="1620"
            height="912"
            decoding="async"
            data-nimg="future"
            className="hero-with-carousel__image"
            style={{ color: 'transparent' }}
          />
        </div>
        <h1 className="hero-with-carousel__heading fw-bolder" style={{marginTop:"14rem"}}>The Freedom to every home that you deserve.</h1>
        <p className="hero-with-carousel__description">
          Step into a new world of renting, with a true flexibility for both residents and operators—every lease, every time.
        </p>
      </div>
    </section>
  );
}

export default Carasoul;
