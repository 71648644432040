import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './layout/Header';
import Footer from './layout/Footer';

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        {/* <Route index element={<Protect element={<Navigate to={'/clients'} />} />} exact /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;