import React from 'react';

function RatingStar() {
  return (
    <svg className="testimonials-item__icon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5152 5.28193L10.0185 4.59758L8.00833 0.330127C7.95343 0.213287 7.8631 0.118702 7.75153 0.0612089C7.4717 -0.0834504 7.13166 0.037099 6.99174 0.330127L4.9816 4.59758L0.484887 5.28193C0.360913 5.30047 0.247566 5.36167 0.160784 5.45441C0.0558699 5.56733 -0.00194268 5.71924 4.9848e-05 5.87678C0.00204238 6.03431 0.063677 6.18457 0.17141 6.29454L3.42484 9.61614L2.6562 14.3064C2.63818 14.4155 2.64971 14.5278 2.68948 14.6304C2.72926 14.733 2.79569 14.8218 2.88125 14.8869C2.9668 14.952 3.06805 14.9906 3.17353 14.9985C3.279 15.0064 3.38446 14.9831 3.47797 14.9314L7.50004 12.717L11.5221 14.9314C11.6319 14.9926 11.7594 15.013 11.8816 14.9908C12.1898 14.9352 12.397 14.6291 12.3439 14.3064L11.5752 9.61614L14.8287 6.29454C14.9172 6.20367 14.9757 6.08497 14.9934 5.95515C15.0412 5.63059 14.8251 5.33015 14.5152 5.28193V5.28193Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export default RatingStar;
